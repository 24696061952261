import "../styles/item.css";
import React from "react";

import { AiOutlineDelete } from "react-icons/ai";

const Item = ({
  item,
  id,
  deleteItem,
  hideUp,
  hideDown,
  shiftItemUp,
  shiftItemDown,
}) => {
  return (
    <div className="container">
      <h1 className="rank">{id + 1}</h1>
      <div className="arrow-container">
        <p
          className={hideUp ? "hide" : undefined}
          onClick={() => {
            shiftItemUp(id);
          }}
        >
          /\
        </p>
        <p
          className={hideDown ? "hide" : undefined}
          onClick={() => {
            shiftItemDown(id);
          }}
        >
          \/
        </p>
      </div>
      <h1 className="item-title">{item}</h1>
      <AiOutlineDelete
        className="delete-icon"
        onClick={() => {
          deleteItem(id);
        }}
      />
    </div>
  );
};

export default Item;
