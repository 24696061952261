import "../styles/list.css";
import { useState } from "react";

import Item from "./Item";

const List = () => {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");

  // Clears all items
  const clearList = () => {
    setItems([]);
  };

  // Deletes item based on index
  const deleteItem = (id) => {
    setItems((prev) => prev.filter((item, index, arr) => index !== id));
  };

  // Shift item up a rank
  const shiftItemUp = (index) => {
    // Cant shift top ranked item
    if (index === 0) {
      return;
    }

    // Swaps 2 indexes and sets state
    setItems((prev) => {
      let data = [...prev];
      let temp = data[index - 1];
      data[index - 1] = data[index];
      data[index] = temp;
      return data;
    });
  };

  const shiftItemDown = (index) => {
    // Cant shift lowest ranked item
    if (index === items.length - 1) {
      return;
    }

    // Swaps 2 indexes and sets state
    setItems((prev) => {
      let data = [...prev];
      let temp = data[index + 1];
      data[index + 1] = data[index];
      data[index] = temp;
      return data;
    });
  };

  // Adds item after user presses enter in textbox
  const enterKeyToAddItem = (event) => {
    // event.preventDefault(); // sometimes useful
    if (event.target.value === "") {
      return;
    }
    // Enter is pressed
    if (event.keyCode === 13) {
      setItems((prev) => [...prev, newItem]);
      event.target.value = "";
    }
  };

  return (
    <div className="item-container">
      {items.length > 0 && (
        <button className="clear-button" onClick={clearList}>
          Clear List
        </button>
      )}
      <ol>
        {items.map((item, id) => (
          <li key={id}>
            <Item
              item={item}
              id={id}
              deleteItem={deleteItem}
              hideUp={id === 0}
              hideDown={id === items.length - 1}
              shiftItemUp={shiftItemUp}
              shiftItemDown={shiftItemDown}
            />
          </li>
        ))}
      </ol>
      <input
        autoFocus
        type="text"
        id="newItem"
        placeholder="Add new item here and press enter"
        className="item-text"
        name="newItem"
        onChange={(e) => {
          setNewItem(e.target.value);
        }}
        onKeyDown={(e) => {
          enterKeyToAddItem(e);
        }}
      ></input>
    </div>
  );
};

export default List;
